<template>
    <div v-if="preload">
        <!-- Loader component -->
        <LoaderMexico/>
    </div>
    <div v-else>
        <div v-if="afiliado">
            <input type="hidden" name="idAfiliado" :value="afiliado">
        </div>
        <div class="section-container">
            <div class="bg-blue text-center text-white py-4" v-if="form?.rechazo == null">
                <h1 class="title">
                    ¡Felicidades! <br>
                    Tienes un prestamo preaprobado de
                </h1>
            </div>
            <div class="bg-orange text-center py-4 text-blue position-relative overflow-hidden" v-if="form?.monto || form?.rechazo == null">
                <img class="first-triangle position-absolute" src="@/assets/img/home/triangulo-naranja.svg" />
                <img class="second-triangle position-absolute" src="@/assets/img/home/triangulo-naranja-2.svg" />
                <!-- Monto -->
                <h1 class="monto">
                    ${{ formatNumber(form?.monto ?? 0, 2) }}
                </h1>
                <p class="regular-text mb-0 regular-text medium-text">
                    en mensualidades ajustadas a tus ingresos
                </p>
            </div>
            <div class="text-blue text-center regular-text py-3" v-if="form?.rechazo == null && form?.posible_prestamo && form?.posible_prestamo > 0">
                <span v-if="form?.coche" class="mb-2 medium-text ">
                    Con tu coche {{ form?.coche }} <span v-if="form?.posible_prestamo">te podemos ofrecer hasta <span class="text-bold">${{ formatNumber(form?.posible_prestamo,2)  }}</span></span>
                </span>
                <p class="mb-0">
                    (Sujeto a estudio crediticio)
                </p>
            </div>
            <div class="mt-md-3 mt-3">
                <p class="text-center text-bold subtitulo mb-1">
                    Datos personales
                </p>
                <p class="text-blue text-center medium-text">
                    Finaliza tu solicitud gratis y sin compromiso.
                </p>
                <div class="form-container mx-auto pt-3 pb-5">
    
                    <!-- Input del nombre -->
                    <div class="mb-3">
                        <input type="text" class="form-control custom-input" :class="{'is-invalid': errors.nombre }" v-model="form.nombre" placeholder="Nombre"/>
                        <p v-if="errors.nombre" class="text-danger mb-0">
                            Ingresa un nombre válido ...
                        </p>
                    </div>
                    
                    <!-- Input del CP -->
                    <div class="mb-3">
                        <InputCP :errorFlag="errors.cp"/>
                    </div>
    
                    <!-- Input del Télefono -->
                    <div class="mb-3">
                        <input type="text" class="form-control custom-input" :class="{'is-invalid': errors.telefono }" v-model="form.telefono" placeholder="Teléfono (10 dígitos)"/>
                        <p v-if="errors.telefono" class="text-danger mb-0">Ingresa número telefónico válido...</p>
                    </div>
                    
                    <!-- Input del email -->
                    <div class="mb-3">
                        <input type="email" class="form-control   custom-input" :class="{'is-invalid': errors.email }" v-model="form.email" placeholder="Email" />
                        <p v-if="errors.email" class="text-danger mb-0">Ingresa un email válido...</p>
                    </div>
                    
    
                    <!-- Checkboxes -->
                    <div class="mx-auto mb-5">
                        <CheckAviso :errorFlag="errors.aviso" />
                        <CheckInformacion :errorFlag="errors.informacion" />
                    </div>

                    <!-- Botón para confirmar (Naranja) -->
                    <div class="button-extend">
                        <ButtonConfirm text="Solicitar préstamo" :icon="false" :clickButton="valdiateForm"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import store from "@/store";
    import { mapState } from "vuex";

    import LoaderMexico from '@/components/FormularioMexico/Loading.vue';
    import CheckAviso from "@/components/FormularioMexico/Inputs/CheckAviso.vue";
    import CheckInformacion from "@/components/FormularioMexico/Inputs/CheckInformacion.vue";
    import ButtonConfirm from '@/components/FormularioMexico/Inputs/ButtonConfirm.vue';
    import InputCP from "@/components/FormularioMexico/Inputs/InputCP.vue";

    // Regex para validar números
    const numberRegex = /^[0-9]*$/;
    
    export default {
        name: 'preaprobado',
        data(){
            return{
                form: {
                    nombre: '',
                    cp: '',
                    telefono: '',
                    email: '',
                    estado: '',
                    aviso: false,
                    informacion: false,
                    anio: null,
                    marca: null,
                    modelo: null,
                    kms: null,
                    factura: null,
                    monto: null,
                    coche: null,
                    rechazo: null
                },
                errors: {
                    nombre: false,
                    cp: false,
                    telefono: false,
                    email: false,
                    aviso: false,
                    informacion: false
                },
                leerMas: false,
                preload: false
            }
        },
        store,
        computed: mapState({
            aviso: (state) => state?.formulario?.datos?.legal_advise,
            informacion: (state) => state?.formulario?.datos?.lopd,
            cp: (state) => state?.formulario?.codigo_postal,
            estado: (state) => state?.formulario?.estado,
            afiliado: (state) => state?.afiliado
        }),
        methods: {
            // Dar formato numérico con dos decimales
            formatNumber(number) {
                return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            // Valida el CP y el estado
            validateCP: function(){
                if(this.cp?.length !== 5 && (this.estado !== null || this.estado !== '')){
                    this.errors.cp = true;
                }else{
                    this.errors.cp = false;
                }
            },
            // Valida el nombre
            validateNombre: function(value){
                if(value.length < 1){
                    this.errors.nombre = true;
                }else{
                    this.errors.nombre = false;
                }
            },
            // Validación del teléfono
            validateTelefono: function(value){
                // Detecta que el teléfono tenga 10 dígitos
                if(!value || value.toString().length !== 10){
                    this.errors.telefono = true;
                }else{
                    this.errors.telefono = false;
                }
            },
            // Validación del email
            validateEmail: function(value){
                // Regex de email
                const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(emailPattern.test(value)){
                    this.errors.email = false;
                }else{
                    this.errors.email = true;
                }
            },
            // Validación de los checkboxes
            validateSelectores: function() {
                const { aviso, informacion } = this.form
                
                if(!aviso){
                    this.errors.aviso = true;
                }else{
                    this.errors.aviso = false;
                }
                if(!informacion){
                    this.errors.informacion = true;
                }else{
                    this.errors.informacion = false;
                }
            },
            // Validación de cada checkbox por separado
            validateSelector: function(name){
                const { aviso, informacion } = this.form
                
                if(name === 'aviso'){
                    if(!aviso){
                        this.errors.aviso = true;
                    }else{
                        this.errors.aviso = false;
                    }
                }
                if(name === 'informacion'){
                    if(!informacion){
                        this.errors.informacion = true;
                    }else{
                        this.errors.informacion = false;
                    }
                }
            },
            // Validación del formulario
            valdiateForm: async function(){
                this.preload = true;
                const { nombre, telefono, email } = this.form
                
                this.validateNombre(nombre);
                await this.validateCP();
                // Se eliminan la máscara del teléfono
                const phoneFormated = telefono.split("-").join('')
                this.validateTelefono(phoneFormated);
                this.validateEmail(email)
                this.validateSelectores();
                
                const { nombre: nombreError, cp: cpError, email: emailError, telefono: telefonoError, aviso: avisoError, informacion: informacionError } = this.errors

                if(!nombreError && !cpError && !telefonoError && !emailError && !informacionError && !avisoError &&  this.estado !== null){
                    // Not errors
                    // Si no hay errores, entonces envía el formulario
                    await this.sendForm()
                }
                this.preload = false;
            },
            // Envio de formulario a API
            sendForm: async function(){
                this.preload = true;
                const { nombre, telefono, email, aviso, informacion, kms, marca, modelo, anio, factura,importe,posible_prestamo } = this.form

                // Se obtiene la URL de orien del local storage
                let url_origen = localStorage.getItem("url_origen") ? localStorage.getItem("url_origen") : this.formulario.url_origen;

                // Se elimina la máscara del input teléfono
                const phoneFormated = telefono.split("-").join('')

                // Inicialización de data para enviar a la API
                let data = {
                    name: nombre,
                    phone: phoneFormated,
                    email: email,
                    zip_code: this.cp,
                    car_km: kms,
                    car_brand: marca,
                    car_model: modelo,
                    car_year_registration: anio,
                    importe: importe,
                    legal_advise: aviso,
                    lopd: informacion,
                    url_origen: url_origen,
                    titular: factura,
                    posible_prestamo: posible_prestamo
                };

                // Llamada a la API
                await axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}createSolicitudApiMexico`, data).then( (response) => {
                    this.preload = false;
                    const { token_enlace: tokenEnlace, solicitud, errors } = response.data;
                    if(errors){
                        // Detecta error de validación de datos
                        this.launchErrorValidator(errors)
                    }else{
                        // Se quita el form de los localstorage
                        localStorage.removeItem('form')
                        const idSolicitud = tokenEnlace.slice(14)
                        store.commit("setTokenEnlace", tokenEnlace);
                        store.commit("setIdSolicitud", idSolicitud);
                        store.commit("setSolicitud", solicitud);
                        store.commit('setEmail', data.email)

                        if (solicitud.numeroestado >= 9000) {
                            // Solicitud denegada
                            store.commit("setStep", 10);
                            this.$router.push({
                                path: "/solicitud/denegada/"+ tokenEnlace,
                                hash: null,
                            });
                        }else{
                            // Se redirige a la página correspondiente
                            if (solicitud.coche_marca === "") {
                                this.$router.push({
                                    path: "/solicitud/coche/" + tokenEnlace,
                                    hash: null,
                                });
                            }else {
                                this.$router.push({
                                    path: "/formulario/" + tokenEnlace,
                                    hash: null,
                                });
                            }
                        }
                    }
                    
                }).catch((error) => {
                    console.log(error)
                    // Error en la solicitud, re dirección a la página de error
                    localStorage.setItem('error', 'Falla en solicitud, ¿Reiniciar?')
                    this.$router.push({
                        path: "/error",
                        hash: null,
                    });
                })
            },
            launchErrorValidator: function(errors){
                // Error de validación de datos en paso 1
                this.errors.nombre = errors.name ? true : false;
                this.errors.telefono = errors.phone ? true : false;
                this.errors.email = errors.email ? true : false;
                this.errors.cp = errors.zip_code ? true : false;
                const otherErrors = [];
                if(errors.titular){
                    otherErrors.push('Factura')
                }
                if(errors.car_km){
                    otherErrors.push('Kilometraje')
                }
                if(errors.car_brand){
                    otherErrors.push('Marca')
                }
                if(errors.car_model){
                    otherErrors.push('Modelo')
                }
                if(errors.car_year_registration){
                    otherErrors.push('Año')
                }
                if(!this.errors.nombre && !this.errors.telefono && !this.errors.cp && !this.errors.email && otherErrors.length){
                    this.$router.push({
                        path: "/",
                        hash: null,
                    });
                }
            },
            // Validar si ya existe el evento en el datalayer y en caso de no existir, la agrega
            addEvent: function(eventText){
                window.dataLayer = window.dataLayer || [];
                const filtrado = window.dataLayer.filter(element => element?.event == eventText);
                if(filtrado.length == 0){
                    window.dataLayer.push({
                        event: eventText,
                    });
                }
            }
        },
        watch: {
            'form.nombre': function(value){
                this.validateNombre(value)
                this.addEvent('datos_personales')
            },
            'form.telefono': function(value, oldVal){
                // Se elimina máscara del teléfono
                if(value.length > 12){
                    this.form.telefono = value.slice(0, 12)
                }
                let onlyNumberPhone = value.split("-").join('')
                if(onlyNumberPhone.length > 10){
                    onlyNumberPhone = onlyNumberPhone.slice(0, 10)
                }
                if(value.length >= oldVal.length){
                    // Se valida que sea numérico
                    const flag = numberRegex.test(onlyNumberPhone)
                    const sizeOfValue = onlyNumberPhone.length
                    if(flag){
                        if(sizeOfValue === 2 || sizeOfValue === 6){
                            if(value.length === 2 || value.length === 7){
                                // En la posición 2 y 7 agrega un - para firmar la máscara
                                this.form.telefono += '-'
                            }
                        }
                        this.validateTelefono(onlyNumberPhone)
                    }else{
                        // Si no pasa la validación de solo números, entonces no se actualiza el valor
                        this.form.telefono = oldVal
                    }
                }else{
                    this.validateTelefono(onlyNumberPhone)
                }
                this.addEvent('datos_personales')
            },
            'form.email': function(value){
                this.validateEmail(value)
                this.addEvent('datos_personales')
            },
            'form.informacion': function(){
                this.validateSelector('informacion')
            },
            'form.aviso': function(){
                this.validateSelector('aviso')
            },
            'informacion': function(value){
                this.form.informacion = value;
            },
            'aviso': function(value){
                this.form.aviso = value
            },
            'estado': function(){
                this.validateCP()
            },
            'cp': function(){
                this.addEvent('datos_personales')
            }

        },
        components: { LoaderMexico, CheckAviso, CheckInformacion, ButtonConfirm, InputCP },
        created: function(){
            // Se obtiene el form de localstorage
            const localForm = JSON.parse(localStorage.getItem('form'));
            console.log(localForm)
            // En caso de no existir, entonces se redirecciona al form
            if(!localForm || localForm === ''){
                this.$router.push({
                    path: "/",
                    hash: null,
                });
            }else{
                const { monto, kms, factura, anio, marca, modelo,importe } = localForm
                this.form = { ...this.form, ...localForm }
                // Detecta que si tenga monto, kms, factura, anio, marca y modelo, en caso de no tener uno, se debe redirigir al home
                if(monto == null || !kms || !(factura == 'si' || factura == 'no') || anio == null || marca == null || modelo == null || importe == null){
                    this.$router.push({
                        path: "/",
                        hash: null,
                    });
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";
    .section-container{
        margin-top: 120px;
        font-family: 'Montserrat';
    }
    .bg-blue{
        background-color: $blue;
    }
    .bg-orange{
        background-color: transparentize($orange, .9)!important;
    }
    .medium-text{
        font-family: 'Montserrat-Medium';
        font-weight: 500;
    }
    .title{
        font-family: 'Montserrat-Bold';
        font-size: 40px;
    }
    .subtitulo{
        font-size: 25px;
    }
    .monto{
        font-family: 'Montserrat-Bold';
        font-size: 60px;
    }
    .text-blue{
        color: $blue;
    }
    .regular-text{
        font-size: 20px;
    }

    .first-triangle{
        bottom: -5px;
        left: 0;
        height: 75%;
    }

    .button-extend {
       text-align: center;
       width: 100%;
    }

        @include media-breakpoint-up(lg) {
            .button-extend {
                width: 222%;
                text-align: unset;
            }
        }

    .second-triangle{
        top: -5px;
        right: 0;
        height: 75%;
    }

    .text-bold{
        font-family: 'Montserrat-Bold';
    }

    .custom-input{
        border-radius: 10px;
        border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
        padding: 11px 15px;
        min-height: 48px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button{
            -webkit-appearance: none;
        }
        &.is-invalid{
            border: 2px solid $red-2!important;
        }
    }

    .custom-input:placeholder-shown{
        color: rgba(153, 153, 153, 0.80);
    }

    .custom-input:focus,
    .custom-input:active{
        outline: -webkit-focus-ring-color auto 1px;
        box-shadow: none;
        &.is-invalid{
            outline: 2px solid $red-2;
        }
    }

    .border-dotted{
        border-style: dashed!important;
    }
    .shared-input{
        border: none!important;
        border-radius: 0!important;
        min-height: 48px;
        &:focus-visible{
            outline: none;
        }
    }
    .shared-input-container{
        border-radius: 10px;
        border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.4));
        overflow: hidden;
        &:focus-within{
            border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
            outline: -webkit-focus-ring-color auto 1px;
            &.is-invalid{
                outline: 2px solid $red-2;
            }
        }
        &.is-invalid{
            border: 2px solid $red-2;
            .disabled-input{
                background-color: transparentize($red-2, .8);
            }
        }
        &.success{
          border: 2px solid $blue;
          color: $blue;
        }
    }

    // Button


    .background-orange{
        background-color: $orange;
    }

    .checkbox {
        width: 1.6em;
        height: 1.6em;
        background: #fff;
        border-radius: 50%;
        vertical-align: middle;
        border: 2px solid $blue;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        &:checked{
            background-image: url("../../assets/img/checked_icon.svg");
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .verMas {
        color: #ffa500;
        font-weight: 600;
        cursor: pointer;
    }

    .form-container{
        max-width: 380px;
    }

    @include media-breakpoint-only(xs){
        .form-container{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    @include media-breakpoint-down(sm){
        .section-container{
            margin-top: 120px;
            font-family: 'Montserrat';
        }
    }

    @include media-breakpoint-down(xs){
        .section-container{
            margin-top: 80px;
            font-family: 'Montserrat';
        }
    }

    @include media-breakpoint-down(md){
        .first-triangle{
            height: 50%;
        }
        .second-triangle{
            height: 50%;
        }

        .title{
            font-size: 30px;
        }

        

        .monto{
            font-size: 35px;
        }

        .regular-text{
            font-size: 13px;
        }

        .subtitulo{
            font-size: 18px;
        }

        .btn-solicitar{
            font-size: 18px;
        }
    }
</style>